import { isCOM } from '../../../utils/Locale';

const getUnifiedDomainLinks = (links, locale) => {
  if (!isCOM()) {
    return links;
  }

  return links.map((link) => {
    const externalURL = link?.url?.external;

    if (!externalURL || !locale) {
      return link;
    }

    const newLink = link;

    newLink.url.external = `/${locale.toLowerCase()}${externalURL}`;

    return newLink;
  });
};

const getDEULinks = () => [
  {
    id: 'footer-imprint',
    text: 'footer.imprint.link.siteInformation',
    title: 'Site Information',
    url: { external: '/impressum/' },
  },
  {
    id: 'footer-privacy',
    text: 'footer.imprint.link.privacyPolicy',
    title: 'Privacy Policy',
    url: { external: '/datenschutz/' },
  },
  {
    id: 'footer-security',
    text: 'footer.imprint.link.security',
    title: 'Security',
    url: { external: '/it-sicherheit/' },
  },
  {
    id: 'footer-terms',
    text: 'footer.imprint.link.termsAndConditions',
    title: 'Terms and Conditions',
    url: { external: '/agb/' },
  },
  {
    id: 'footer-userCentric',
    text: 'footer.imprint.link.privacySettings',
    title: 'Privacy Settings',
    url: '#uc-corner-modal-show',
  },
];

const getAUTLinks = () => [
  {
    id: 'footer-imprint',
    text: 'footer.imprint.link.siteInformation',
    title: 'Site Information',
    url: { external: '/impressum/' },
  },
  {
    id: 'footer-privacy',
    text: 'footer.imprint.link.privacyPolicy',
    title: 'Privacy Policy',
    url: { external: '/datenschutz/' },
  },
  {
    id: 'footer-security',
    text: 'footer.imprint.link.security',
    title: 'Security',
    url: { external: '/it-sicherheit/' },
  },
  {
    id: 'footer-terms',
    text: 'footer.imprint.link.termsAndConditions',
    title: 'Terms and Conditions',
    url: { external: '/agb/' },
  },
  {
    id: 'footer-userCentric',
    text: 'footer.imprint.link.privacySettings',
    title: 'Privacy Settings',
    url: '#uc-corner-modal-show',
  },
];

const getEURLinks = () => [
  {
    id: 'footer-imprint',
    text: 'footer.imprint.link.siteInformation',
    title: 'Site Information',
    url: { external: '/site-information/' },
  },
  {
    id: 'footer-privacy',
    text: 'footer.imprint.link.privacyPolicy',
    title: 'Privacy Policy',
    url: { external: '/privacy-policy/' },
  },
  {
    id: 'footer-security',
    text: 'footer.imprint.link.security',
    title: 'Security',
    url: { external: '/security/' },
  },
  {
    id: 'footer-terms',
    text: 'footer.imprint.link.termsAndConditions',
    title: 'Terms and Conditions',
    url: { external: '/terms/' },
  },
  {
    id: 'footer-userCentric',
    text: 'footer.imprint.link.privacySettings',
    title: 'Privacy Settings',
    url: '#uc-corner-modal-show',
  },
];

const getGBRLinks = () => [
  {
    id: 'footer-privacy',
    text: 'footer.imprint.link.privacyPolicy',
    title: 'Privacy Policy',
    url: { external: '/privacy-policy/' },
  },
  {
    id: 'footer-cookie',
    text: 'footer.imprint.link.cookiePolicy',
    title: 'Cookie Policy',
    url: { external: '/cookie-policy/' },
  },
  {
    id: 'footer-security',
    text: 'footer.imprint.link.security',
    title: 'Security',
    url: { external: '/security/' },
  },
  {
    id: 'footer-terms',
    text: 'footer.imprint.link.termsAndConditions',
    title: 'Terms & Conditions',
    url: { external: '/terms-and-conditions/' },
  },
  {
    id: 'footer-imprint',
    text: 'footer.imprint.link.siteInformation',
    title: 'Site Information',
    url: { external: '/disclaimer' },
  },
  {
    id: 'footer-userCentric',
    text: 'footer.imprint.link.privacySettings',
    title: 'Privacy Settings',
    url: '#uc-corner-modal-show',
  },
];

const getIRLLinks = () => [
  {
    id: 'footer-imprint',
    text: 'footer.imprint.link.siteInformation',
    title: 'Site Information',
    url: { external: '/site-information/' },
  },
  {
    id: 'footer-privacy',
    text: 'footer.imprint.link.privacyPolicy',
    title: 'Private Policy',
    url: { external: '/privacy-policy/' },
  },
  {
    id: 'footer-security',
    text: 'footer.imprint.link.security',
    title: 'Security',
    url: { external: '/security/' },
  },
  {
    id: 'footer-terms',
    text: 'footer.imprint.link.termsAndConditions',
    title: 'Terms and Conditions',
    url: { external: '/terms/' },
  },
  {
    id: 'footer-userCentric',
    text: 'footer.imprint.link.privacySettings',
    title: 'Privacy Settings',
    url: '#uc-corner-modal-show',
  },
];

const getFRALinks = () => [
  {
    id: 'footer-imprint',
    text: 'footer.imprint.link.siteInformation',
    title: 'Site Information',
    url: { external: isCOM() ? '/fr-fr/mentions-legales/' : '/mentions-legales/' },
  },
  {
    id: 'footer-privacy',
    text: 'footer.imprint.link.privacyPolicy',
    title: 'Privacy Policy',
    url: {
      external: isCOM() ? '/fr-fr/protection-des-donnees/' : '/protection-des-donnees/',
    },
  },
  {
    id: 'footer-security',
    text: 'footer.imprint.link.security',
    title: 'Security',
    url: { external: isCOM() ? '/fr-fr/securite-informatique/' : '/securite-informatique/' },
  },
  {
    id: 'footer-terms',
    text: 'footer.imprint.link.termsAndConditions',
    title: 'Terms and Conditions',
    url: { external: isCOM() ? '/fr-fr/conditions/' : '/conditions/' },
  },
  {
    id: 'footer-userCentric',
    text: 'footer.imprint.link.privacySettings',
    title: 'Privacy Settings',
    url: '#uc-corner-modal-show',
  },
];

const getNLDLinks = () => [
  {
    id: 'footer-imprint',
    text: 'footer.imprint.link.siteInformation',
    title: 'Site Information',
    url: { external: '/disclaimer/' },
  },
  {
    id: 'footer-privacy',
    text: 'footer.imprint.link.privacyPolicy',
    title: 'Privacy Policy',
    url: { external: '/privacybeleid/' },
  },
  {
    id: 'footer-security',
    text: 'footer.imprint.link.security',
    title: 'Security',
    url: { external: '/veiligheid/' },
  },
  {
    id: 'footer-terms',
    text: 'footer.imprint.link.termsAndConditions',
    title: 'Terms and Conditions',
    url: { external: '/algemene-voorwaarden/' },
  },
  {
    id: 'footer-userCentric',
    text: 'footer.imprint.link.privacySettings',
    title: 'Privacy Settings',
    url: '#uc-corner-modal-show',
  },
];

const getESPLinks = () => [
  {
    id: 'footer-imprint',
    text: 'footer.imprint.link.siteInformation',
    title: 'Site Information',
    url: { external: '/aviso-legal/' },
  },
  {
    id: 'footer-privacy',
    text: 'footer.imprint.link.privacyPolicy',
    title: 'Privacy Policy',
    url: { external: '/privacidad/' },
  },
  {
    id: 'footer-cookie',
    text: 'footer.imprint.link.cookiePolicy',
    title: 'Cookie Policy',
    url: { external: '/privacidad/#paragraph-3' },
  },
  {
    id: 'footer-security',
    text: 'footer.imprint.link.security',
    title: 'Security',
    url: { external: '/seguridad/' },
  },
  {
    id: 'footer-terms',
    text: 'footer.imprint.link.termsAndConditions',
    title: 'Terms and Conditions',
    url: { external: '/terminos-condiciones/' },
  },
  {
    id: 'footer-userCentric',
    text: 'footer.imprint.link.privacySettings',
    title: 'Privacy Settings',
    url: '#uc-corner-modal-show',
  },
];

const getPOLLinks = () => [
  {
    id: 'footer-imprint',
    text: 'footer.imprint.link.siteInformation',
    title: 'Site Information',
    url: {
      external: '/pl-pl/nota-prawna/',
    },
  },
  {
    id: 'footer-privacy',
    text: 'footer.imprint.link.privacyPolicy',
    title: 'Privacy Policy',
    url: {
      external: '/pl-pl/polityka-prywatnosci/',
    },
  },
  {
    id: 'footer-security',
    text: 'footer.imprint.link.security',
    title: 'Security',
    url: {
      external: '/pl-pl/bezpieczenstwo/',
    },
  },
  {
    id: 'footer-terms',
    text: 'footer.imprint.link.termsAndConditions',
    title: 'Terms and Conditions',
    url: {
      external: '/pl-pl/regulamin/',
    },
  },
  {
    id: 'footer-userCentric',
    text: 'footer.imprint.link.privacySettings',
    title: 'Privacy Settings',
    url: '#uc-corner-modal-show',
  },
];

const getFINLinks = () => [
  {
    id: 'footer-imprint',
    text: 'footer.imprint.link.siteInformation',
    title: 'Site Information',
    url: {
      external: '/fi-fi/tietosuoja/',
    },
  },
  {
    id: 'footer-privacy',
    text: 'footer.imprint.link.privacyPolicy',
    title: 'Privacy Policy',
    url: {
      external: '/fi-fi/turvallisuus/',
    },
  },
  {
    id: 'footer-security',
    text: 'footer.imprint.link.security',
    title: 'Security',
    url: {
      external: '/fi-fi/yleiset-kayttoehdot/',
    },
  },
  {
    id: 'footer-terms',
    text: 'footer.imprint.link.termsAndConditions',
    title: 'Terms & conditions',
    url: {
      external: '/fi-fi/vastuuvapaus/',
    },
  },
  {
    id: 'footer-userCentric',
    text: 'footer.imprint.link.privacySettings',
    title: 'Privacy Settings',
    url: '#uc-corner-modal-show',
  },
];

export const getLegalLinks = () => ({
  DEU: getUnifiedDomainLinks(getDEULinks(), 'de-de'),
  AUT: getUnifiedDomainLinks(getAUTLinks(), 'de-at'),
  EUR: getEURLinks(),
  GBR: getUnifiedDomainLinks(getGBRLinks(), 'en-gb'),
  IRL: getUnifiedDomainLinks(getIRLLinks(), 'en-ie'),
  FRA: getFRALinks(),
  NLD: getUnifiedDomainLinks(getNLDLinks(), 'nl-nl'),
  ESP: getUnifiedDomainLinks(getESPLinks(), 'es-es'),
  POL: getPOLLinks(),
  FIN: getFINLinks(),
});

export const CREDITS = {
  AUT: [
    {
      logo: 'aut-tuv-raisin.svg',
      title: 'tuv',
      url: 'https://www.tuev-saar.de/zertifikat/tk45016/',
    },
  ],

  DEU: [
    {
      logo: 'climate-action.svg',
      title: 'climateAction',
      url: '',
    },
    {
      logo: 'deu-tuv-raisin.svg',
      title: 'tuv',
      url: 'https://www.tuev-saar.de/zertifikat/tk45015/',
    },
  ],

  EUR: [],

  IRL: [],

  GBR: [],

  NLD: [],

  FRA: [],

  ESP: [
    {
      logo: 'sello_confianza-online.svg',
      title: 'Confianza Online',
      url: 'https://www.confianzaonline.es/empresas/raisin.htm',
    },
  ],

  POL: [],
  FIN: [],
};
