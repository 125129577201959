export const currencies = {
  fr: 'EUR',
  es: 'EUR',
  nl: 'EUR',
  de: 'EUR',
  'de-de': 'EUR',
  'de-at': 'EUR',
  at: 'EUR',
  it: 'EUR',
  en: 'GBP',
  'en-us': 'EUR',
  'en-ie': 'EUR',
  'en-gb': 'GBP',
  'pl-pl': 'PLN',
  'fi-fi': 'EUR',
};

export const UNIFIED_DOMAIN_LOCALES = {
  'fr-fr': 'fr',
  'de-de': 'de',
  'de-at': 'de-AT',
  'es-es': 'es',
  'nl-nl': 'nl',
  'en-ie': 'en-IE',
  'en-gb': 'en-GB',
};

export const getCurrencyForLocale = (locale) => currencies[locale.toLowerCase()] || 'EUR';

export const LOCALE_STORAGE_KEY = 'obs-locale';

export const getQueryParam = (param) => {
  const search = window.location.search.substring(1);

  if (search.indexOf(param) > -1) {
    const searchParams = search
      .split('&')
      .reduce((acc, item) => (item ? [...acc, item] : acc), [])
      .map((keyValue) => {
        return keyValue.split('=');
      })
      .reduce(
        (acc, params) => ({
          ...acc,
          [params[0]]: params[1],
        }),
        {},
      );

    return searchParams[param] || undefined;
  }

  return undefined;
};

export const makeLocaleRegionUppercase = (locale) => {
  let formattedLocale = locale;
  const localeRegex = /[a-z]{2}-[a-z]{2}/i;

  if (localeRegex.test(formattedLocale)) {
    const [code, region] = formattedLocale.split('-');

    formattedLocale = `${code.toLowerCase()}-${region.toUpperCase()}`;
  }

  return formattedLocale;
};

export const formatLocaleToUnifiedDomain = (countryLocale) => {
  const locale = getQueryParam('locale') || countryLocale;
  const formattedLocale = UNIFIED_DOMAIN_LOCALES[locale.toLowerCase()] || locale;

  return makeLocaleRegionUppercase(formattedLocale);
};

export const saveLocaleInLocalStorage = (countryLocale) => {
  const locale = formatLocaleToUnifiedDomain(countryLocale);

  const acceptedLocales = [
    'de',
    'es',
    'fr',
    'en-GB',
    'en-US',
    'en-IE',
    'nl',
    'de-AT',
    'pl-PL',
    'fi-FI',
  ];

  if (acceptedLocales.includes(locale)) {
    localStorage.setItem(LOCALE_STORAGE_KEY, locale);
  } else {
    localStorage.setItem(LOCALE_STORAGE_KEY, 'en-US');
  }
};

export const getLocaleFromLocalStorage = () => {
  try {
    return localStorage.getItem(LOCALE_STORAGE_KEY) || 'de';
  } catch (e) {
    return 'de';
  }
};

export const isCOM = () => window.location.hostname?.includes('raisin.com');

export const getLocalizedUrl = (locale, path) => {
  if (isCOM() && window.location.pathname.includes(locale)) {
    return `${locale}/${path}`;
  }

  return path;
};
