import { justEtfRed, justEtfBlue } from 'ui-lib/src/styles/styles.scss';
import transform from 'lodash/transform';
import camelCase from 'lodash/camelCase';

import isObject from 'lodash/isObject';

export const DEVELOPMENT_ENV_DOMAIN_REGION_REGEX = /bk-(deu|gbr)-(.*)\.raisin-dev\.network/;
const ONBOARDING_ENV_DOMAIN_REGION_REGEX = /bk-(deu|gbr)-onboarding\.raisin-onboarding\.network/;

export const GBR_REGION = 'GBR';
export const DEU_REGION = 'DEU';

export const PROVIDERS = {
  justEtf: 'justetf',
  raisin: 'Raisin',
};

export const JUSTETF_COLORS = {
  red: justEtfRed,
  blue: justEtfBlue,
};

export const COMING_FROM_REGISTRATION_PARAM = '?fromReg=true';
export const PRIVATE_EQUITY_PRODUCT_NAME = 'PE';
export const PRIVATE_EQUITY_STATE = {
  CREATED: 'CREATED',
  ORDERED: 'ORDERED',
  ISSUE_IDENTIFIED: 'ISSUE_IDENTIFIED',
  REQUESTED: 'REQUESTED',
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  CLOSING_PENDING: 'CLOSING_PENDING',
  REJECTED: 'REJECTED',
};
export const PRIVATE_EQUITY_ISSUE = {
  PENDING_KYC: 'PENDING_KYC',
};

export const PRODUCT_INSTANCE_STATE = {
  CREATED: 'CREATED',
  ORDERED: 'ORDERED',
  ISSUE_IDENTIFIED: 'ISSUE_IDENTIFIED',
  REQUESTED: 'REQUESTED',
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  CLOSING_PENDING: 'CLOSING_PENDING',
  REJECTED: 'REJECTED',
};

export const ORDER_STATE = {
  DRAFTED: 'DRAFTED',
  VALIDATED: 'VALIDATED',
  PREPARED: 'PREPARED',
  CONFIRMED: 'CONFIRMED',
  EXPORTED: 'EXPORTED',
  IN_PROGRESS: 'IN_PROGRESS',
  EXECUTED: 'EXECUTED',
  REJECTED: 'REJECTED',
  REVOCATION_REQUESTED: 'REVOCATION_REQUESTED',
  REVOKED: 'REVOKED',
  CANCELLED: 'CANCELLED',
};

export const PRODUCT_CATEGORIES = {
  LIQUID: 'LIQUID',
  ILLIQUID: 'ILLIQUID',
};

export const DWM_PORTFOLIOS = ['DWM30', 'DWM50', 'DWM70', 'DWM100'];
export const ROBO_PORTFOLIOS = ['ROBO30', 'ROBO50', 'ROBO70', 'ROBO100'];

export const PRODUCT_TYPES = {
  PE: 'PE',
  MODEL: 'MODEL',
};

export const ORDER_TYPE = {
  BUY: 'BUY',
  SELL_FULL: 'SELL_FULL',
  SELL_PARTIAL: 'SELL_PARTIAL',
  SAVINGS_PLAN_BUY: 'SAVINGS_PLAN_BUY',
  DWM_MIGRATION_CONSENT: 'DWM_MIGRATION_CONSENT',
};

export const PORTFOLIO_ORDER_INSTRUCTION_TYPE = {
  ONBOARDING: 'ONBOARDING',
  PRIVATE_EQUITY_ONBOARDING: 'PRIVATE_EQUITY_ONBOARDING',
  CAPITAL_CALL: 'CAPITAL_CALL',
  LIQUID_CONFIGURATION: 'LIQUID_CONFIGURATION',
  BUY: 'BUY',
  TOTAL_SELL: 'TOTAL_SELL',
  PARTIAL_SELL: 'PARTIAL_SELL',
  SAVINGS_PLAN_CREATE: 'SAVINGS_PLAN_CREATE',
  SAVINGS_PLAN_DELETE: 'SAVINGS_PLAN_DELETE',
  UNDEFINED: 'UNDEFINED',
};

export const PORTFOLIO_ORDER_INSTRUCTION_STATE = {
  DRAFTED: 'DRAFTED',
  VALIDATED: 'VALIDATED',
  PREPARED: 'PREPARED',
  ISSUE_IDENTIFIED: 'ISSUE_IDENTIFIED',
  CONFIRMED: 'CONFIRMED',
  EXPORTED: 'EXPORTED',
  EXECUTED: 'EXECUTED',
  REJECTED: 'REJECTED',
  FUNDED: 'FUNDED',
  IN_PROGRESS: 'IN_PROGRESS',
  REVOCATION_REQUESTED: 'REVOCATION_REQUESTED',
  REVOKED: 'REVOKED',
  CANCELLED: 'CANCELLED',
};

export const ASSET_CLASSES = {
  FIXED_INCOME: 'FIXED_INCOME',
};

export const weltInvestIdPattern = '^WI\\d{2}$';
export const justEtfIdPattern = '^J\\w{1}\\d{2}$';

export const getDistributorForPortfolioId = (id) => {
  if (id.match(weltInvestIdPattern)) {
    return PROVIDERS.raisin;
  }
  if (id.match(justEtfIdPattern)) {
    return PROVIDERS.justEtf;
  }

  return null;
};

export const isJustEtfPortfolio = (provider) => {
  return provider === PROVIDERS.justEtf;
};

export const isWeltInvestPortfolio = (provider) => {
  return provider === PROVIDERS.raisin;
};

export const MIN_AMOUNT_WITH_SAVINGS_PLAN = 50;

export const isValidAmount = (amount, min, max) => amount >= min && amount <= max;

export const getNamespaceName = () => {
  // we are on staging
  if (window.location.host?.includes('staging')) return 'staging';
  // we are on onboarding
  if (window.location.host?.includes('onboarding')) return 'onboarding';
  // we are on dev
  //  www.dev-raisin.com
  //  www.dev-raisin.co.uk
  //  www.dev-weltsparen.de
  if (window.location.host?.includes('dev-weltsparen') || window.location.host?.includes('dev-raisin')) {
    return 'develop';
  }

  if (window.location.host?.includes('localhost')) return 'develop';

  if (window?.location?.host?.indexOf('raisin-dev.network') >= 0) {
    const name = DEVELOPMENT_ENV_DOMAIN_REGION_REGEX.exec(window.location.host);

    return name ? name[2] : 'develop';
  }

  // we are on production
  return 'production';
};

export const getRegionName = () => {
  if (window.location.host?.includes('localhost')) {
    if (process.env.RAISIN_REGION) {
      return process.env.RAISIN_REGION;
    }

    return DEU_REGION;
  }
  const hostname = window?.location?.hostname;
  const developmentName = DEVELOPMENT_ENV_DOMAIN_REGION_REGEX.exec(hostname);

  if (developmentName) {
    return developmentName[1].toUpperCase();
  }
  const onboardingName = ONBOARDING_ENV_DOMAIN_REGION_REGEX.exec(hostname);

  if (onboardingName) {
    return onboardingName[1].toUpperCase();
  }

  return hostname?.endsWith('.co.uk') ? GBR_REGION : DEU_REGION;
};

export const hasSpecificPortfolio = (piaProductInstances = [], portfolios = []) =>
  piaProductInstances?.some(
    ({ productCategory, state, instanceTemplateDomainId }) =>
      productCategory === PRODUCT_CATEGORIES.LIQUID &&
      portfolios.includes(instanceTemplateDomainId) &&
      ![PRODUCT_INSTANCE_STATE.REJECTED, PRODUCT_INSTANCE_STATE.CLOSED].includes(state),
  );

export const hasMigratedRobo = (piaProductInstances = []) =>
  piaProductInstances?.some(
    ({ productCategory, state }) =>
      productCategory === PRODUCT_CATEGORIES.LIQUID &&
      ![PRODUCT_INSTANCE_STATE.REJECTED, PRODUCT_INSTANCE_STATE.CLOSED].includes(state),
  );

export const getDWMTotalValue = (productInstances = []) =>
  productInstances
    ?.filter(
      ({ productCategory, state }) =>
        productCategory === PRODUCT_CATEGORIES.LIQUID &&
        ![PRODUCT_INSTANCE_STATE.REJECTED, PRODUCT_INSTANCE_STATE.CLOSED].includes(state),
    )
    ?.reduce((total, current) => total + current.portfolioValue, 0) || 0;

export const shouldDisplayHolidaysBanner = () => {
  const now = new Date();
  const start = new Date(2021, 11, 22, 0, 1);
  const end = new Date(2022, 0, 2, 23, 59);

  return now > start && now < end;
};

export const hasInvestmentProductAccess = (pomProducts = {}) =>
  Object.values(pomProducts).some((value) => value);

export const shouldDisplayProcessingBuyOrderBanner = (orders = []) => {
  const IN_PROGRESS_ORDER_STATES = new Set([
    ORDER_STATE.CONFIRMED,
    ORDER_STATE.EXPORTED,
    ORDER_STATE.IN_PROGRESS,
  ]);

  return (
    orders.some(
      ({ order_state, instruction }) =>
        IN_PROGRESS_ORDER_STATES.has(order_state) &&
        instruction.some(
          ({ instruction_type, order_instruction_state }) =>
            instruction_type === PORTFOLIO_ORDER_INSTRUCTION_TYPE.BUY &&
            order_instruction_state === PORTFOLIO_ORDER_INSTRUCTION_STATE.FUNDED,
        ),
    ) || false
  );
};

export const hasPrivateEquityProductsInAnyState = (piaProductInstances = []) =>
  piaProductInstances.some(
    ({ productCategory }) => productCategory === PRODUCT_CATEGORIES.ILLIQUID,
  );

export const toCamelCase = (obj) => {
  return transform(obj, (acc, value, key, target) => {
    const camelKey = Array.isArray(target) ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? toCamelCase(value) : value;
  });
};

export const LINKS = {
  ONBOARDING_WELCOME: '/wealth-management/order/welcome',
};
