import React from 'react';
import { getBasePath, getExternalUrl } from '../../components/Header/data/utils';
import { EXTERNAL_LINKS } from '../../components/Header/Navigation/menuLinks';
import { getNamespaceName } from '../IPUtils';
import { isCOM } from '../Locale';

const mapRFRouteToCPRoute = {
  '/Products': '/products',
  '/Products/overnight': '/products/overnight',
  '/Products/notice': '/products/notice',
};

export const redirectProducts = ({ url, Products }) => {
  if (url !== '/Products/easyAccess') {
    return window.location.assign(getExternalUrl(mapRFRouteToCPRoute[url] || '/products'));
  }

  return <Products />;
};

export const redirectToReferAFriend = () => {
  window.location.assign(`${getBasePath()}/refer-a-friend`);
};

export const getLocaleBasePath = (locale) => {
  const lowerLocale = locale.toLowerCase();

  if (lowerLocale === 'en' || lowerLocale === 'en-us') {
    return 'en';
  }

  if (!isCOM()) {
    return '';
  }

  const localePath = {
    fr: 'fr-fr',
    'pl-pl': 'pl-pl',
    'fi-fi': 'fi-fi',
    de: 'de-de',
    'de-at': 'de-at',
    es: 'es-es',
    'en-ie': 'en-ie',
    nl: 'nl-nl',
    'en-gb': 'en-gb',
  };

  return localePath[lowerLocale] || '';
};

export const getRelativePathWithLocalePrefix = (path) => {
  const localeBasePath = getLocaleBasePath(document.documentElement.lang);

  return localeBasePath !== '' ? `/${localeBasePath}${path}` : path;
};

export const goTo = (path) => {
  const relativePath = getRelativePathWithLocalePrefix(path);

  window?.location?.assign(relativePath);
};

export const goToLoginPage = () => {
  goTo(EXTERNAL_LINKS.GLOBAL.LOGIN);
};

export const isPensionLogoutNeeded = () => {
  const environment = getNamespaceName();

  return environment === 'production' && window.location.host.endsWith('weltsparen.de');
};

export const goToDeProdPensionLogoutPage = () => {
  window?.location?.assign(EXTERNAL_LINKS.DEU.PENSION_LOGOUT);
};
